var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_vm.amendment_status == 2 ? _c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("verification")))]), _c('p', [_vm._v(" " + _vm._s(_vm.$t("approved-will-amendment")) + " ")]), _vm.amendment_status != 2 ? _c('hr', {
    staticClass: "primary"
  }) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "card-body"
  }, [_c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('div', {
    staticClass: "card-title m-0"
  }, [_c('h5', {
    staticClass: "card-label m-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("pdpa")) + " ")])])]), _c('div', {
    staticClass: "card-body"
  }, [_c('p', {
    staticClass: "text-justify"
  }, [_vm._v(" " + _vm._s(_vm.$t("pdpa-para1")) + " ")]), _c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.acknowledged_pdpa,
      expression: "acknowledged_pdpa"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "name": "checkPdpa",
      "id": "checkPdpa",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.acknowledged_pdpa) ? _vm._i(_vm.acknowledged_pdpa, "1") > -1 : _vm.acknowledged_pdpa
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.acknowledged_pdpa,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.acknowledged_pdpa = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.acknowledged_pdpa = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.acknowledged_pdpa = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "label-agree-term",
    attrs: {
      "for": "checkPdpa"
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t("understand-pdpa")) + " ")])])])])]), _c('br'), _c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('div', {
    staticClass: "card-title m-0"
  }, [_c('h5', {
    staticClass: "card-label m-0"
  }, [_vm._v(_vm._s(_vm.$t("will-confirmation")))])])]), _c('div', {
    staticClass: "card-body"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("i")) + ", "), _c('strong', {
    staticStyle: {
      "text-decoration": "underline"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.name)
    }
  }), _vm._v(", " + _vm._s(_vm.$t("with-ic-no")) + " "), _c('strong', {
    staticStyle: {
      "text-decoration": "underline"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.ic_number)
    }
  })]), _c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.acknowledged_confirmation,
      expression: "acknowledged_confirmation"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "exampleCheck2",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.acknowledged_confirmation) ? _vm._i(_vm.acknowledged_confirmation, "1") > -1 : _vm.acknowledged_confirmation
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.acknowledged_confirmation,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.acknowledged_confirmation = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.acknowledged_confirmation = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.acknowledged_confirmation = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "label-agree-term",
    attrs: {
      "for": "exampleCheck2"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t("agree-and-understand")) + ":")])])]), _c('p', [_vm._v(" 1. " + _vm._s(_vm.$t("appoint-executor-trustee")) + " ")]), _c('p', [_vm._v(" 2. " + _vm._s(_vm.$t("revoke-will")) + " ")]), _vm.amendment_status == 4 ? _c('div', [_vm.signature ? _c('img', {
    staticClass: "mt-3",
    staticStyle: {
      "width": "130px"
    },
    attrs: {
      "src": _vm.signature.public_path,
      "alt": "",
      "srcset": ""
    }
  }) : _vm._e()]) : _vm._e(), _vm.amendment_status == 4 ? _c('div', [_vm._v(" [ "), _c('strong', {
    domProps: {
      "innerHTML": _vm._s(_vm.name)
    }
  }), _vm._v("] ")]) : _vm._e()])]), !_vm.btnSendTOAwaris ? _c('div', [_vm.amendment_status == 0 ? _c('div', {
    staticClass: "row d-flex justify-content-center m-4"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-otp-input', {
    ref: "otpInput",
    attrs: {
      "input-classes": "otp-input",
      "separator": "-",
      "num-inputs": 6,
      "should-auto-focus": true,
      "is-input-num": true
    },
    on: {
      "on-change": _vm.handleOnChange,
      "on-complete": _vm.handleOnComplete
    }
  })], 1)]) : _vm._e(), _vm.amendment_status == 0 ? _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [!_vm.otpSent ? _c('b-btn', {
    staticClass: "btn-sm btn-warning m-2",
    on: {
      "click": _vm.requestOtp
    }
  }, [_vm._v(_vm._s(_vm.btnotptext))]) : _vm._e(), _vm.otpSent ? _c('span', [_c('small', [_vm._v(_vm._s(_vm.$t("otp-sent")))])]) : _vm._e()], 1) : _vm._e()]) : _vm._e(), _vm.userRole == 'Staff' && _vm.amendment_status == 1 ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("amendment-agree-info")))])]) : _vm._e(), _c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_vm.pengesahan && _vm.amendment_status == 1 ? _c('b-button', {
    staticClass: "btn btn-success m-1",
    on: {
      "click": _vm.awarisVerified
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")))]) : _vm._e(), _vm.pengesahan && _vm.amendment_status == 1 ? _c('b-button', {
    staticClass: "btn btn-danger m-1",
    on: {
      "click": _vm.awarisCancel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")))]) : _vm._e()], 1)])])]), _c('b-modal', {
    ref: "modalRequestOtp",
    attrs: {
      "title": "Mohon OTP",
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("no-otp-send-to")) + " +6018-9581443")])]), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(_vm._s(_vm.$t("close")))])], 1)])], 1)]), _c('div', {
    staticClass: "text-right mt-3"
  }, [_vm.amendment_status == 0 ? _c('b-button', {
    staticClass: "btn btn-primary m-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.updateAmendmentStatus
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("send-application")) + " ")]) : _vm._e()], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }